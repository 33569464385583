<template>
  <div>
    <div class="flex bg-white shadow rounded p-4">
      <div class="w-1/4">
        <div class="bg-gray-200 p-4 rounded border mr-4">
          <FormInsights v-model="usersApi.model" @submit="getUsers()" :isLoading="usersApi.isLoading" />
        </div>
      </div>
      <div class="flex-grow">
        <UtilLoader :isLoading="usersApi.isLoading">
          <p v-if="usersApi.data.users" class="mb-2">{{ usersApi.data.count }} users found</p>
          <UtilMatch
            v-for="match in usersApi.data.users"
            :match="match"
            :showStats="false"
            :key="`seller-${match.userRef}`"
            class="bg-gray-100 border-2 rounded p-3 mb-3 cursor-pointer hover:border-gray-500"
            @click="openMatch"
          />
        </UtilLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, startCase } from 'lodash'
import Api from '@/lib/api'
import UtilLoader from '@/components/utils/UtilLoader'
import UtilMatch from '@/components/utils/UtilMatch'
import FormInsights from '@/components/forms/FormInsights'

export default {
  name: 'InsightsUsers',
  components: {
    UtilLoader,
    UtilMatch,
    FormInsights
  },
  data() {
    return {
      usersApi: new Api()
    }
  },
  created() {
    this.setUsers()
    //this.getUsers()
  },
  computed: {
    compQueryUrl() {
      let query = cloneDeep(this.usersApi.model)
      query.startDate = this.$date(query.startDate).format('DD-MM-YYYY')
      query.endDate = this.$date(query.endDate).format('DD-MM-YYYY')
      return JSON.stringify(query)
    }
  },
  filters: {
    startCase(value) {
      return startCase(value)
    }
  },
  methods: {
    setUsers() {
      let q = this.$route.query.q

      if (q) {
        q = JSON.parse(q)

        this.usersApi.model = q

        this.getUsers()
      } else {
        this.usersApi.model = {
          include: [],
          exclude: [],
          coordinates: []
        }
      }
    },

    async getUsers() {
      await this.usersApi.get('http://localhost:3000/local/analytics/v1/kpis/users/cohorts')

      this.$router.push({ query: { q: this.compQueryUrl } })
    },

    openMatch(userRef) {
      this.$modal.open('ModalMatch', { userRef: userRef })
    }
  }
}
</script>
